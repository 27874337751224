import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../_metronic/layout";
import Encrease from "../_metronic/components/encrease/Encrease";
export default function BasePage() {
  return (
    <>
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          <ContentRoute exact path="/" component={Encrease} />
          <Redirect to="error/error-v6" />
        </Switch>
      </Suspense>
    </>
  );
}
